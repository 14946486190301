import './modal.css'
// import VkResult from './VkResult'
import wall from './democracy/image/wallModal.jpeg'
// import reloadGame from './democracy/image/reload.png'
// import share from './democracy/image/share.png'
import Share from './Share'
import { useEffect } from 'react'
import setLidders from './setLidders'


const WinnerScreen = props => {
    useEffect(()=>{
        setLidders(props.textTime, props.allTime)
        // eslint-disable-next-line
    },[])

    const reload = () => {
       window.location.reload()
    }

    const shareWin = () => {
        Share(props.textTimeShare)
    }


    return (
        <div className="contModal">
        <div className="windowModal" style={{backgroundImage:`url(${wall})`, backgroundSize:"cover", height:"170px", paddingTop:"20px"}}>
            <p className="textModal" style={{margin:"0px", marginBottom:"5px"}}>Ура победа!!!</p>
            <p className="textModal" style={{color:"#FF6E00", margin:"0px"}}>Твой рекорд {props.textTime}</p>
            <button className="btnVideo" style={{height:"32px", padding:"5px", fontSize:"16px", marginTop:"20px"}} onClick={reload}>Начать заново</button>
            <button className="btnVideo" style={{height:"32px", padding:"5px", fontSize:"16px", color:"#FF6E00"}} onClick={shareWin}>Поделиться</button>
        </div>
        </div>
    )
}
export default WinnerScreen
