import './Loading.css'
import { useState, useEffect } from 'react';

const Loading = () => {

    const [dots, setDots] = useState(1);

    useEffect(() => {
      const interval = setInterval(() => {
        setDots((prevDots) => (prevDots % 3) + 1);
      }, 500); // Интервал обновления точек (500 мс)
  
      return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
    }, []);

    return (
        <div className="contLoading">
            <div className="contPLoad">
             <p className="hLoading">Загрузка{".".repeat(dots)}</p>
            </div>
        </div>
    )
}

export default Loading