import bridge from '@vkontakte/vk-bridge';

const VkLeftSide = () => {
bridge.send('VKWebAppAddToFavorites')
  .then((data) => { 
    if (data.result) {
      // Игра добавлена в левое меню...
    }
  })
  .catch ((error) => {
    console.log(error); // Ошибка...
  });
}

export default VkLeftSide