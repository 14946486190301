
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "vkoligarh.firebaseapp.com",
    projectId: "vkoligarh",
    storageBucket: "vkoligarh.appspot.com",
    messagingSenderId: "507120559876",
    appId: "1:507120559876:web:8a923d0a8e61284b879251",
    measurementId: "G-4HK80Z24MZ"
  };

export default firebaseConfig