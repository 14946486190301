import { useEffect } from "react";
import add from'./democracy/image/add.png'
import stepBack from'./democracy/image/stepBack.png'
import save from'./democracy/image/save.png'
import downLoad from'./democracy/image/downLoad.png'
import wall from './democracy/image/wall.jpeg'
import lamp from './democracy/image/lamp.png'
import music from './democracy/image/music.png'
import sound from "./democracy/image/sound.png"
import cake from './democracy/image/cake.png'
import wallBtn from './democracy/image/wallModal.jpeg'
import video from './democracy/image/videoOrange.png'
import menuBtn from './democracy/image/MenuBtn.png'
import cakeBig from './democracy/image/cakeBig.png'


import invite from './democracy/image/invite.png'
import newGame from './democracy/image/reload.png'
import playGame from './democracy/image/play.png'
import down from './democracy/image/down.png'



const PreloadImages = () => {
    useEffect(() => {
      const images = [add, stepBack, save, downLoad, wall, lamp, 
        music, sound, cake, wallBtn, video, menuBtn,
        cakeBig,invite, newGame, playGame,down  ]; // Добавьте все изображения в массив
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
      });
    }, []);
  
    return null; // Этот компонент не рендерит ничего
  };

  export default PreloadImages