import bridge from '@vkontakte/vk-bridge';
// import { useEffect } from 'react';

const Share = (textTime) => {
  bridge.send('VKWebAppShowWallPostBox', {
    message: `У меня получилось убрать все печеньки с поля всего за ${textTime}! Сможешь быстрее?`,
    attachment: 'https://vk.com/app51864951_736810561',
    // owner_id: 743784474
  })
  .then( (data) => {
    // Запись отправлена на стену
    // console.log(`Идентификатор записи: ${data.post_id}`);
  })
  .catch( (e) => {
    // console.log("Ошибка!", e);
  })

return 

}

export default Share