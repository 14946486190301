import './modalShare.css'
import { useState, useEffect } from 'react'
// import VkResult from './VkResult'
import wall from './democracy/image/wallModal.jpeg'
// import reloadGame from './democracy/image/reload.png'
// import share from './democracy/image/share.png'
import ShareCakes from './ShareCakes'
import cake from './democracy/image/cake.png'

const WinnerScreen = props => {

    const [textModal, setTextModal] = useState("")
    const [headText, setHeadText] = useState([])
    const [headText2, setHeadText2] = useState([])

    const reload = () => {
        props.closeShareCakes()
        props.noShowAgain(props.countCakes)
    }

    const shareWin = () => {
        ShareCakes(props.countCakes, props.closeShareCakes)
        props.noShowAgain(props.countCakes)
    }

    useEffect(()=>{
    console.log(props.countCakes)
       if (props.countCakes === 50) {
        setTextModal("Убраны первые 50 печенек, не останавливайся!")
       }
       else if (props.countCakes === 100) {
        setTextModal("Первая сотня позади, попробуй убрать все печеньки!")
       }
       else if (props.countCakes === 500) {
        setTextModal("Убрано целых 500 печенек, самое время рассказать об этом друзьям!")
       }
       else if (props.countCakes === 1000) {
        setTextModal("1000 печенек убрано, все еще есть шанс убрать их все!")
       }
       else if (props.countCakes ===5000) {
        setTextModal("5000 позади, твоему терпению можно позавидовать!")
       }
       else if (props.countCakes ===10000) {
        setTextModal("10000 печенек! Поделись результатом! Такое под силу не каждому...")
       }
    }, [props.countCakes])

    useEffect(()=>{
        const textHead = "НОВЫЙ"
        const textArray = Array.from(textHead)
        setHeadText(textArray);

        const textHead2 = "РЕЗУЛЬТАТ"
        const textArray2 = Array.from(textHead2)
        setHeadText2(textArray2);
    },[])



    return (
        <div className="contModalShare">
        <div className="windowModalShare" style={{backgroundImage:`url(${wall})`, backgroundSize:"cover", paddingTop:"20px"}}>
        <div className="contLetters" >
        {headText &&  headText.map((letter, ind) => (
                    <div key={ind} className="contLetter" style={{backgroundImage:`url(${cake})`}}>
                        <p className="letter" >{letter}</p>
                    </div>
        )
        )
    }
        </div>
        <div className="contLetters2" >
        {headText &&  headText2.map((letter, ind) => (
                    <div key={ind} className="contLetter" style={{backgroundImage:`url(${cake})`}}>
                        <p className="letter" >{letter}</p>
                    </div>
        )
        )
    }
        </div>

       
            <div className="contInfo">
                <p className="textModalShare" >{textModal}</p>
            </div>
            <button className="btnVideoShare" style={{height:"32px", padding:"5px", fontSize:"16px", color:"#FF6E00", marginTop:"10px"}} onClick={shareWin}>Поделиться</button>
            <button className="btnVideoShare" style={{height:"32px", padding:"5px", fontSize:"16px", marginTop:"10px"}} onClick={reload}>Продолжить</button>
        </div>
        </div>     
    )
}
export default WinnerScreen
