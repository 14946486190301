import bridge from '@vkontakte/vk-bridge';

const VkScreen = () => {
// Проверяем, добавлена ли игра на главный экран
bridge.send('VKWebAppAddToHomeScreenInfo')
  .then( (data) => {
    if (! data.is_added_to_home_screen) {    // Если не добавлена,
      bridge.send('VKWebAppAddToHomeScreen') //  то добавляем
        .then( (data) => {
          if (data.result) {
            // Игра добавлена.
            // Можно выдать пользователю бонус
            // ...
          } else {
            // Игра уже добавлена
          }
        })
        .catch( (e) => {
          // Пользователь отклонил запрос или 
          // произошла ошибка
        })    
     }
   })
   .catch( (e) => { console.log('Ошибка', e); });
}


export default VkScreen
