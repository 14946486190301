import './modal.css'
import wall from './democracy/image/wallModal.jpeg'

const Modal = props => {
    return (
        <div className="contModal">
        <div className="windowModal" style={{backgroundImage:`url(${wall})`, backgroundSize:"cover"}}>
            <p className="textModal">{props.textModal}</p>
        </div>
        </div>
    )
}
export default Modal
