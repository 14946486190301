import './modal.css'
import wall from './democracy/image/wallModal.jpeg'

const Alarmer = props => {

    const reload = () => {
        window.location.reload()
    }

    const closeAlarm = () => {
        props.closeAlarm()
    }

    return (
        <div className="contModal">
        <div className="windowModal" style={{backgroundImage:`url(${wall})`, backgroundSize:"cover"}}>
            <p className="textModal" style={{marginBottom:"20px", marginTop:"0px"}}>Точно хотите начать игру заново?</p>
            <button className="btnAlarm" style={{color:"#FF6E00"}} onClick={reload}>Да</button>
            <button className="btnAlarm"  onClick={closeAlarm}>Нет</button>
        </div>
        </div>
    )
}
export default Alarmer
