import './TextOne.css'

const TextReveal = () => {

  const text1 = "Добро пожаловать в игру печеньки!!!" 
  const text2 = "Твоя задача убрать все печеньки с поля."
  const text3 = "Убирай находящиеся рядом печеньки с одинаковыми цифрами, или с цифрами дающими в сумме 10."
  const text4 = "По диагонали печеньки убирать нельзя, перенесенные на другую строку можно."
  const text5 = "Пройди игру как можно быстрее. Удачи!!!"

  return (
    <div className="description" >
        <div className="animated-text">
        {text1.split('').map((char, index) => (
        <span key={index} className="char-animation" style={{animationDelay:`${index/10+5}s`, color:"#004225", fontSize:"22px", textShadow:"none"}}>
          {char}
        </span>
      ))}
      </div>
      <br/>
      
      <div className="animated-text">
        {text2.split('').map((char, index) => (
        <span key={index} className="char-animation" style={{animationDelay:`${index/10+3+5}s`}}>
          {char}
        </span>
      ))}
      </div>
      <div className="animated-text">
        {text3.split('').map((char, index) => (
        <span key={index} className="char-animation" style={{animationDelay:`${index/10+7+5}s`}}>
          {char}
        </span>
      ))}
      </div>
      <div className="animated-text">
        {text4.split('').map((char, index) => (
        <span key={index} className="char-animation" style={{animationDelay:`${index/10+16+5}s`}}>
          {char}
        </span>
      ))}
      </div>
      <div className="animated-text" style={{marginBottom:"0px"}}>
        {text5.split('').map((char, index) => (
        <span key={index} className="char-animation" style={{animationDelay:`${index/10+24+5}s`, color:"#004225", fontSize:"22px", textShadow:"none"}}>
          {char}
        </span>
      ))}
      </div>
    </div>
  )
};

export default TextReveal;
