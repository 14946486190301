import './TextOne.css'

const TextRevealMenu = () => {

  const text1 = 'Правила игры "Печеньки"' 
  const text2 = "Твоя задача убрать все печеньки с поля."
  const text3 = "Убирай находящиеся рядом печеньки с одинаковыми цифрами, или с цифрами дающими в сумме 10."
  const text4 = "По диагонали печеньки убирать нельзя, перенесенные на другую строку можно."
  const text5 = "Пройди игру как можно быстрее. Удачи!!!"

  return (
    <div className="description" style={{borderRadius:"15px"}}>
        <div className="animated-text">
        <span className="char-animation" style={{color:"#004225", fontSize:"22px", textShadow:"none"}}>{text1}</span>
      </div>
      <br/>
      
      <div className="animated-text">
        <span className="char-animation" >{text2}</span>
      </div>
      <div className="animated-text">
        <span className="char-animation" >{text3}</span>
      </div>
      <div className="animated-text">
        <span className="char-animation" >{text4}</span>
      </div>
      <div className="animated-text" style={{marginBottom:"0px"}}>
        <span className="char-animation" style={{color:"#004225", fontSize:"22px", textShadow:"none"}}>{text5}</span>
      </div>
    </div>
  )
};

export default TextRevealMenu;
