import './modal.css';
import video from './democracy/image/video.png'
import VkAds from './vkAds'
import wall from './democracy/image/wallModal.jpeg'
// import camera from './democracy/image/camera.png'

const AskVideo = props => {


    const func = () => {
        if (props.nameFunc==="backStep") {
            VkAds()
            setTimeout(()=>{props.backStep()},1000) 
            setTimeout(()=>{props.cancelVideo()},1000)
            setTimeout(()=>{props.backStep()},1000)
            
        } else if (props.nameFunc==="check") {
            VkAds()
            setTimeout(()=>{props.newLamp()},1000)
            setTimeout(()=>{props.cancelVideo()},1000)
            setTimeout(()=>{props.check()},1000)
        }
    }

    const cancelVideo = () => {
        // if (props.nameFunc==="backStep") {
        //     props.backStep()
        // }
        // else if (props.nameFunc==="check") {
        //     props.check()
        //     props.newLamp()
        // }
        props.cancelVideo()
    }

    return (
        <div className="contModal">
        <div className="windowModal" style={{backgroundImage:`url(${wall})`, backgroundSize:"cover"}}>
            <p className="textModal" style={{margin:"10px", marginBottom:"20px", display:"flex"}}>Посмотреть рекламу чтобы {props.textVideo} </p>
            <button className="btnVideo" style={{height:"32px", padding:"5px"}} onClick={func}>Посмотреть <img src={video} alt=">" style={{width:"23px", height:"23px", marginLeft:"20px"}} /></button>
            <button className="btnAlarm" style={{height:"32px", padding:"5px", color:"#FF6E00"}} onClick={cancelVideo}>Не хочу</button>
        </div>
        </div>
    )
}
export default AskVideo
