import bridge from '@vkontakte/vk-bridge';
import SentFire from './SentFire';
import { initializeApp } from 'firebase/app';
import { doc, getDoc, getFirestore } from 'firebase/firestore'; 
import firebaseConfig from './firebaseConfig.js';

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);

const setLidders = (textTime, allTime) => {

  const fetchDocument = async (docId, buy) => {
    const time = parseInt(allTime)
    try {
        // Получаем ссылку на документ с определенным ID
        const docRef = doc(db, 'caces', docId);
        // Извлекаем данные документа
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // Если документ существует, возвращаем данные
            const data = docSnap.data();
            // console.log('Данные документа:', { id: docSnap.id, ...data });
            // return data;
            if(data.time>allTime && time!==0 ){
              SentFire(docId,buy)
            }
        } else {
          if (time!==0 ) {
          SentFire(docId,buy)
          }
        }
    } catch (error) {
        console.error('Ошибка при извлечении данных:', error);
        SentFire(docId,buy)
    }
};

        const getId = async () => {
          // const userId = 'i1517'
          // const time = parseInt(allTime)
          // const buy = {
          //   userName: "leo",
          //   photo: "userPhoto" ,
          //   textTime: textTime,
          //   time: time
          // }
    
        // fetchDocument(userId, buy)
          try {
            // Получение информации о пользователе
            const data = await bridge.send('VKWebAppGetUserInfo');
            // ID пользователя
            const time = parseInt(allTime)
            const userId = data.id.toString();
            const userName = data.first_name.toString()
            const userPhoto = data.photo_100.toString()
            const buy = {
                userName: userName,
                photo: userPhoto ,
                textTime: textTime,
                time: time
              }
        
            fetchDocument(userId, buy)
          //   console.log(dataF);
          } catch (error) {
            console.error('Error getting user info:', error);
          }
        };
    
        getId();
}

export default setLidders