import './Menu.css'
import VkInvite from './VkInvite';
import TextRevealMenu from './TextRevealMenu';
import cake from './democracy/image/cakeBig.png'
// import cakeSmall from './democracy/image/cake.png'
import invite from './democracy/image/invite.png'
import newGame from './democracy/image/reload.png'
import playGame from './democracy/image/play.png'
import down from './democracy/image/down.png'

import wall from './democracy/image/wall.jpeg'
import Switch from '@mui/material/Switch';
import sound from './democracy/image/sound.png'
import music from './democracy/image/music.png'
import { useState, useRef } from 'react';
// import VkTurnirTable from './VkTurnirTable';


const Menu = props => {

const [animeName, setAnimeName] = useState("slideUp")
// Слайд вниз
const blockRef = useRef(null);
let startY;

const handleTouchStart = (event) => {
    startY = event.touches[0].clientY;
  }

const  handleTouchMove = (event) => {
    const deltaY = event.touches[0].clientY - startY;
    if (deltaY > 50) { // Adjust the threshold as needed
    //   console.log('Swiped down!');
      // You can perform any action here
      play()
    }
  }

const play = () => {
    setAnimeName("slideDown")
    setTimeout(()=>{props.hideMenu()},1000) 
}

    return (
        <div className="contMenu" style={{backgroundImage: `url(${wall})`, backgroundSize: 'cover', animationName:animeName}} ref={blockRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
            <div className='contHeadMenu' >
                <div className="headLetterMenu" style={{backgroundImage:`url(${cake})`}}>
                    <h1 className='headMenu'>М</h1>
                </div>
                <div className="headLetterMenu" style={{backgroundImage:`url(${cake})`}}>
                    <h1 className='headMenu'>Е</h1>
                </div>
                <div className="headLetterMenu" style={{backgroundImage:`url(${cake})`}}>
                    <h1 className='headMenu'>Н</h1>
                </div>
                <div className="headLetterMenu" style={{backgroundImage:`url(${cake})`}}>
                    <h1 className='headMenu'>Ю</h1>
                </div>
            </div>

            <div className="contCheckMenu">
            <div>
            <div className="formCheckMenu">
               <Switch  size="normal" color='warning' value={props.playMusic} checked={props.playMusic} onChange={(e)=>{props.changeMisic(e)}} />
               <img src={music} alt="?" style={{width:"22px", height:"22px", marginRight:"5px"}}/>
            </div>
            <p className="soundsP">вкл/выгл музыку</p>
            </div>
            <div className="contCount">
                <p className="textCount">Убрано печенек</p>
                <p className="textCount" style={{color:"#FF6E00", fontSize:"22px", textShadow:"1px 1px 0px rgba(0, 0, 0, 0.6)"}}>{props.score}</p>
            </div>
            <div>
           <div className="formCheckMenu" >
              <Switch  size="normal" color='warning' value={props.playSounds} checked={props.playSounds} onChange={(e)=>{props.changeSounds(e)}} />
              <img src={sound} alt="?" style={{width:"22px", height:"22px", margin:"0px"}}/>
            </div>
            <p className="soundsP">вкл/выгл звуки</p>
            </div>
          </div>
            <div className="contButtons">
                <div>
                <div className="playBtn" onClick={play} style={{backgroundImage: `url(${cake})`, backgroundSize: 'cover'}}>
                    <img className="menuImg" src={playGame} alt=">"/>
                </div>
                <p className="soundsP">Продолжить <br/>игру</p>
                </div>
                <div>
                <div className="playBtn" onClick={()=>{props.visAlarm()}} style={{backgroundImage: `url(${cake})`, backgroundSize: 'cover'}}>
                    <img className="menuImg" src={newGame} alt=">"/>
                </div>
                <p className="soundsP">Новая <br/>игра</p>
                </div>
                <div>
                <div className="playBtn" onClick={()=>{VkInvite()}} style={{backgroundImage: `url(${cake})`, backgroundSize: 'cover'}}>
                    <img className="menuImg" src={invite} alt=">"/>
                </div>
                <p className="soundsP">Пригласить <br/>друзей</p>
                </div>
                {/* <button onClick={()=>{VkTurnirTable()}}>Турнирная таблица</button> */}
            </div>
        <div style={{paddingLeft:"10px", paddingRight:"10px"}}>
          <TextRevealMenu/>
          <img src={down} alt="-" style={{width:"330px", height:"30px", opacity:"0.5", position:"absolute", bottom:"20px", left: "50%", transform: "translateX(-50%)" }} onClick={play}/>
        </div>
       
        </div>
    )
}

export default Menu