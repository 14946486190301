import bridge from '@vkontakte/vk-bridge';
// import { useEffect } from 'react';

const ShareCakes = (countCakes, closeShare) => {
let text = ''
if (countCakes === 50) {
    text = "Первые 50 печенек убраны! Едем дальше!"
} else if (countCakes === 100) {
    text = "Убрано уже 100 печенек, присоединяйтесь!"
} else if (countCakes === 500) {
    text = "500 печенек позади, игра еще не пройдена!"
} else if (countCakes === 1000) {
    text = "Убрана целая тысяча печенек! Кто больше?"
} else if (countCakes === 5000) {
    text = "По статистике лишь один человек из 500 способен убрать 5000 печенек, я среди них, присоединяйтесь!"
} else if (countCakes === 10000) {
    text = "Мой результат 10 000 печенек! Достичь его под силу единицам! А вам слабо?"
}

//   console.log(text)
  closeShare()
  bridge.send('VKWebAppShowWallPostBox', {
    message: text,
    attachment: 'https://vk.com/app51864951_736810561',
    // owner_id: 743784474
  })
  .then( (data) => {
    // Запись отправлена на стену
    // console.log(`Идентификатор записи: ${data.post_id}`);
  })
  .catch( (e) => {
    // console.log("Ошибка!", e);
  })

return 

}

export default ShareCakes