const destroyRow = (destroyNum, gorizNum) => {
    const findAndRemoveSequence = () => {
      for (let i = 0; i < destroyNum.length; i++) {
        if (destroyNum[i] % 9 === 0 || destroyNum[i] === 0) {
          const sequence = [];
          let currentNumber = destroyNum[i];
          let count = 0;
  
          for (let j = 0; j < destroyNum.length; j++) {
            if (destroyNum.includes(currentNumber)) {
              sequence.push(currentNumber);
              currentNumber++;
              count++;
              if (count === 9) {
                // Удаляем найденные числа из destroyNum
                let newDestroyNum = destroyNum.filter(num => !sequence.includes(num));
                const lastSequenceElement = sequence[1];
                newDestroyNum = newDestroyNum.map(num => num > lastSequenceElement ? num - 9 : num);
                // Удаляем элементы из gorizNum по найденным индексам
                const newGorizNum = gorizNum.filter((_, index) => !sequence.includes(index));
                // console.log(newGorizNum)
                return [newDestroyNum, newGorizNum];
              }
            }
          }
        }
      }
      return [destroyNum, gorizNum];
    };
  
    return findAndRemoveSequence();
  }
  
  export default destroyRow;
  