
// import React, { useState } from 'react';
import { useState, useEffect, useRef } from 'react'
import './Cube.css'
import Modal from './Modal'
import Switch from '@mui/material/Switch';
// import { FormControlLabel } from '@mui/material';
// import TextOne from './TextOne';
// import TextTwo from './TextTwo';
import Alarmer from './Alarmer';
import WinnerScreen from './WinScreen';
// import VkAds from './vkAds';
import AskVideo from './AskVideo'
import TextReveal from './TextReveal';
import Menu from './Menu'
import VkScreen from './VkScreen';
import VkLeftSide from './VkLeftSide';
// import VkTurnirTable from './VkTurnirTable';
// import bridge from '@vkontakte/vk-bridge';

import soundPress from './sounds/press.mp3'
import soundDone from './sounds/done.mp3'
import soundWrong from './sounds/wrong.mp3'
import soundAdd from './sounds/add.mp3'
import soundBack from "./sounds/stepBack.mp3"
import soundBackWrong from './sounds/stepBackWrong.mp3'
import soundSaveGame from './sounds/saveGame.mp3'
import soundLoadGame from './sounds/loadGame.mp3'
import soundReload from './sounds/reload.mp3'
import soundWin from './sounds/win.mp3'
import soundBackground from './sounds/background.mp3'
import soundFind from './sounds/find.mp3'
import newResult from './sounds/newResult.mp3'

import add from'./democracy/image/add.png'
import stepBack from'./democracy/image/stepBack.png'
import save from'./democracy/image/save.png'
import downLoad from'./democracy/image/downLoad.png'
// import newGame from'./democracy/image/reload.png'
import wall from './democracy/image/wall.jpeg'
import lamp from './democracy/image/lamp.png'
import music from './democracy/image/music.png'
import sound from "./democracy/image/sound.png"
// import timerImg from './democracy/image/timer.png'
import cake from './democracy/image/cake.png'
// import cakeBig from './democracy/image/cakeBig.png'
// import wall1 from './democracy/image/wall1.jpeg'
import wallBtn from './democracy/image/wallModal.jpeg'
import video from './democracy/image/videoOrange.png'
// import setting from './democracy/image/home.png'
// import setting1 from './democracy/image/setting.png'
// import homeAnime from './democracy/image/home1.png'
import menuBtn from './democracy/image/MenuBtn.png'
import ModalShare from './ModalShare'
import Loading from './Loading';
import PreloadImages from './PreloadImage';
import destroyRow from './destroyRow';


function Cube() {
    const numbersArr = [1,2,3,4,5,6,7,8,9,1,1,1,2,1,3,1,4,1,5,1,6,1,7,1,8,1,9]
    const [chooseNumber, setChooseNumber] = useState([])
    const [chooseIndex, setChooseIndex] = useState([])
    const [destroyNum, setDestroyNum] = useState([])
    const [gorizNum, setGorizNum] = useState(numbersArr)

    const [visModal, setVisModal] = useState(false)
    const [textModal, setTextModal] = useState("")
    //Видимость описания
    const [visDesk, setVisDesc] = useState(true)

    //Музыка
    const [playMusic, setPlayMusic] = useState(true)
    const [playSounds, setPlaySounds] = useState(true)

    //Ход назад
    const [backGorizNum, setBackGorizNum] = useState(null)
    const [backDestroyNum, setBackDestroyNum] = useState([])
    const [visBack, setVisBack] = useState(false)

    //Функция запуска таймера

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [isRuning, setIsRuning] = useState(false)

    //Для лампочки
    const [lampOne, setLampOne] = useState(null)
    const [lampTwo, setLampTwo] = useState(null)

    //Видимость меню
    const [visMenu, setVisMenu] = useState(false)


    //Новая игра
    const [visAlarmer, setVisAlarmer] = useState(false)

    //Победа
    const [visWinner, setVisWinner] = useState(false)
    const [textWinner, setTextWinner] = useState('')

    const [textTime, setTextTime] = useState("")
    //Текст поделиться
    const [textTimeShare, setTextTimeShare] =useState("")

    //Посмотреть видео?
    const [visVideo, setVisVideo] = useState(false)

    //Текст видео
    const [textVideo, setTextVideo] = useState("")

    //Функция видео
    const [nameFunc, setNameFunc] = useState(null)

    //Анимация для лампочки
    const [trans, setTrans] = useState(null)

    //Количество подсказок
    const [countLamp, setCountLamp] = useState(3)

    //Неактивность кнопки перемешать
    const [isDesable, setIsDesable] = useState(false)

    //Видимость окна поделиться количеством печенек
    const [visModalShare, setVisModalShare] = useState(false)

    //Чтобы повторно окно с поделиться не открывалось

    const [showShareAgain, setShowShareAgain] = useState(null)

    //Видимость загрузочного экрана в начале
    const [visLoading, setVisLoading] = useState(true)

    //Убрано печенек
    const [score, setScore] = useState(0)

    //СЕкунды для отправки и сортировки в Фаирбэйз
    const [allTime, setAllTime] = useState(0)


    const noShowAgain = (count) => {
      setShowShareAgain(count)
    }

    //Закрытие окна поделиться
    const closeShareCakes = () => {
      setVisModalShare(false)
    }

    //Воспроизведение музыки после клика по печеньки если перед этим окно было свернуто
    const [playAlloud, setPlayAlloud] = useState(true)
  //Дублирую плэй мьюзик, чтобы при скрытии и открытии страницы, чекбокс не переключался
    const [playMusic1, setPlayMusic1] = useState(true)

    const cancelVideo = () => {
      setVisVideo(false)
    }
    //Извлекаем id и данные клиента
    

    //Функция пермешивания элементов
    const shuffleArray = ()=> {
      if (!isDesable) {
      const newArray = [...numbersArr];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
      }
      playSound("add")
      setGorizNum(newArray);
    }
    }

    //Воспроизведение аудио
    const playSound = (soundId) => {
      if (soundId === "background") {
          const audio = document.getElementById(soundId);
          if (audio) {
            audio.play();
            setPlayAlloud(false)
        }
      } 
      else if (playSounds){
        const audio = document.getElementById(soundId);
        if (audio) {
          audio.play();
        }
      }
    
      };

      //Проверка видимости приложения
    const [hide, setHide] = useState(false)

    useEffect(()=>{
      //Загрузочный экран
      setTimeout(()=>{
        setVisLoading(false)
      },5000)
      const visChange = () => {
        setPlayAlloud(true)
        setHide(document.hidden)

        if (!hide) {
          sendTimer()
          setPlayMusic1(false)
        } else {
         getTimer()
         setPlayMusic1(true)
        }
      }
      const sendTimer = () => {
        localStorage.setItem('secondsHide', JSON.stringify(seconds));
        localStorage.setItem('minutesHide', JSON.stringify(minutes));
        localStorage.setItem('hoursHide', JSON.stringify(hours));
        localStorage.setItem('allTimeHide', JSON.stringify(allTime));
        }
      

      const getTimer = () => {
        setSeconds(JSON.parse(localStorage.getItem('secondsHide')))
        setMinutes(JSON.parse(localStorage.getItem('minutesHide')))
        setHours(JSON.parse(localStorage.getItem('hoursHide')))
        setAllTime(JSON.parse(localStorage.getItem('allTimeHide')))
        }

      document.addEventListener("visibilitychange", visChange);
    })


    const audioRef = useRef(null);

        //Сохраняем данные в локал сторэйдж
        const saveGame = () => {
          localStorage.setItem('gorizNum', JSON.stringify(gorizNum));
          localStorage.setItem('destroyNum', JSON.stringify(destroyNum));
          localStorage.setItem('seconds', JSON.stringify(seconds));
          localStorage.setItem('minutes', JSON.stringify(minutes));
          localStorage.setItem('hours', JSON.stringify(hours));
          localStorage.setItem('score', JSON.stringify(score));
          localStorage.setItem('allTime', JSON.stringify(allTime));
    
          // saveVk("score", score)
          setVisModal(true)
          playSound("saveGame")
          setTextModal("Игра успешно сохранена")
          setTimeout(()=>{setVisModal(false)},2000)
        }

    //ВЫтаскиваем данные из локал сторэйдж
    const loadGame = () => {
      const gorizNum = JSON.parse(localStorage.getItem('gorizNum')) || [];
      const destroyNum = JSON.parse(localStorage.getItem('destroyNum')) || [];
      const seconds = JSON.parse(localStorage.getItem('seconds')) || 0;
      const minutes = JSON.parse(localStorage.getItem('minutes')) || 0;
      const hours = JSON.parse(localStorage.getItem('hours')) || 0;
      const getScore = JSON.parse(localStorage.getItem('score')) || 0;
      const getAllTime = JSON.parse(localStorage.getItem('allTime')) || 0;
      if (gorizNum && destroyNum ) {
        setGorizNum(gorizNum)
        setDestroyNum(destroyNum)
        setVisModal(true)
        setTextModal("Игра успешно загружена")
        setTimeout(()=>{setVisModal(false)},2000)
        setVisDesc(false)
        setSeconds(seconds)
        setMinutes(minutes)
        setHours(hours)
        setAllTime(getAllTime)
        playSound("loadGame")
        if (getScore === 0) {
          setScore(destroyNum.length)
        } else {
          setScore(getScore)
        }
      } else {
        playSound("backWrong")
      }
    }

    const closeAlarm = () => {
      setVisAlarmer(false)
    }

    const changeMisic = (e) => {
      setPlayMusic(e.target.checked)
       setTimeout(()=>{playSound("background")}, 100) ;
    }

    const changeSounds = (e) => {
      setPlaySounds(e.target.checked)
    }
    //Ход назад визуализация
    const backStep1 = ()=>{
      if(backGorizNum && visBack) {
      setNameFunc('backStep')
      setTextVideo("сделать ход назад.")
      setVisVideo(true)
    } else {
      playSound("backWrong")
    }
  }

    const backStep = () => {
        setGorizNum(backGorizNum)
        setDestroyNum(backDestroyNum)
        playSound("back")
        setVisBack(false)
        setVisVideo(false)
    }

    //Скрыть меню
    const hideMenu = () => {
      setVisMenu(false)
    }


    //Проверка на победу
    useEffect(()=>{
      if ((score===50 || score===100 || score===500 || score===1000 || score===5000 || score===10000) && showShareAgain!==score ) {
        setVisModalShare(true)
        playSound("result")
      }
      const sum = gorizNum.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      if (sum ===0) {
        playSound("win")
        setTimeout(()=>{setVisModal(false)},5000)
        setVisWinner(true)
        setTextWinner(`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`)
        formatTime()
        //Турнирная таблица. сделаю, размещу
        // setTimeout(()=>{VkTurnirTable(textTimeShare)}, 1000)
      }
      // eslint-disable-next-line
    },[gorizNum])


    //Текст для секундомера для победы и поделиться
    const formatTime = () => {
      // const duration = moment.duration(milliseconds);
      // const hours = duration.hours();
      // const minutes = duration.minutes();
      // const seconds = duration.seconds();
  
      // Используем moment.js для форматирования времени и обработки склонений
      const formattedHours = hours > 0 ? `${hours} час${hours !== 1 ? (hours < 5 ? 'а' : "ов") : ''}` : '';
      const formattedMinutes = minutes > 0 ? `${minutes} минут${(minutes === 1 || minutes === 21 || minutes === 31 || minutes === 41 || minutes === 51) ? 'а' : (((minutes >1 && minutes < 5)|| (minutes >21 && minutes < 25)|| (minutes >31 && minutes < 35)|| (minutes >41 && minutes < 45)|| (minutes >51 && minutes < 55) ) ? 'ы': "")}` : '';
      // const formattedMinutes = minutes > 0 ? `${minutes} минут${minutes !== 1 ? 'ы' : 'у'}` : '';
      const formattedSeconds = seconds > 0 ? `${seconds} секунд${(seconds === 1 || seconds === 21 || seconds === 31 || seconds === 41 || seconds === 51) ? 'а' : (((seconds >1 && seconds < 5)|| (seconds >21 && seconds < 25)|| (seconds >31 && seconds < 35)|| (seconds >41 && seconds < 45)|| (seconds >51 && seconds < 55) ) ? 'ы': "")}` : '';
  
      setTextTime(`${formattedHours} ${formattedMinutes} ${formattedSeconds}`);
      //Текст для поделиться в ВК
      const formattedHours1 = hours > 0 ? `${hours} час${hours !== 1 ? (hours < 5 ? 'а' : "ов") : ''}` : '';
      const formattedMinutes1 = minutes > 0 ? `${minutes} минут${(minutes === 1 || minutes === 21 || minutes === 31 || minutes === 41 || minutes === 51) ? 'у' : (((minutes >1 && minutes < 5)|| (minutes >21 && minutes < 25)|| (minutes >31 && minutes < 35)|| (minutes >41 && minutes < 45)|| (minutes >51 && minutes < 55) ) ? 'ы': "")}` : '';
      // const formattedMinutes = minutes > 0 ? `${minutes} минут${minutes !== 1 ? 'ы' : 'у'}` : '';
      const formattedSeconds1 = seconds > 0 ? `${seconds} секунд${(seconds === 1 || seconds === 21 || seconds === 31 || seconds === 41 || seconds === 51) ? 'у' : (((seconds >1 && seconds < 5)|| (seconds >21 && seconds < 25)|| (seconds >31 && seconds < 35)|| (seconds >41 && seconds < 45)|| (seconds >51 && seconds < 55) ) ? 'ы': "")}` : '';
  
      setTextTimeShare(`${formattedHours1} ${formattedMinutes1} ${formattedSeconds1}`);
    }

  
    useEffect(() => {
      if (audioRef.current) {
        audioRef.current.volume = 0.5; // Установить начальную громкость (от 0 до 1)
      }
      setTimeout(()=>{VkScreen()}, 10000) 
      setTimeout(()=>{VkLeftSide()}, 10000) 
    }, []);

    const startTimer = () => {
      // if (audioRef.current) {
      //   audioRef.current.volume = 0.1; // ачальная громкость (от 0 до 1)
      // }
      playSound("background")
      const timerInterval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
        setAllTime((prevAllTime) => prevAllTime + 1);
      }, 1000);
      setIsRuning(true)
      return () => clearInterval(timerInterval);
    };

  
    useEffect(() => {
      if (seconds === 60) {
        setSeconds(0);
        setMinutes((prevMinutes) => prevMinutes + 1);
      }
      if (minutes === 60) {
        setMinutes(0);
        setHours((prevHours) => prevHours + 1);
      }
    }, [seconds, minutes]);
    //Конец функции таймер

    //Функция добавления цифр
    const addNumbers = () => {
        setVisDesc(false)
        playSound("add")
        const filteredArr = gorizNum.filter((element) => element !== 0);
        setGorizNum((prevGorizNum) => [...prevGorizNum, ...filteredArr])
    }

    const comparison = (updatedChooseNumber, updatedChooseIndex) => {

        //Вычисляем сумму между двумя элементами (меньший будет первым)
        if (updatedChooseIndex[0]<updatedChooseIndex[1]) {
            var result = sumElementsBetweenIndexes(gorizNum, updatedChooseIndex[0], updatedChooseIndex[1])
            var verticalResult = sumVerticalElements(gorizNum, updatedChooseIndex[0], updatedChooseIndex[1])
        }else {
            result = sumElementsBetweenIndexes(gorizNum, updatedChooseIndex[1], updatedChooseIndex[0])
            verticalResult = sumVerticalElements(gorizNum, updatedChooseIndex[1], updatedChooseIndex[0])
        }
        //Проверка на главное условие игры
        if ((updatedChooseNumber[0]===updatedChooseNumber[1] || updatedChooseNumber[0]+updatedChooseNumber[1]===10) && (((result-(updatedChooseNumber[0]+updatedChooseNumber[1])===0) || result/updatedChooseNumber[0]===2) || ((verticalResult-(updatedChooseNumber[0]+updatedChooseNumber[1])===0)|| (verticalResult/updatedChooseNumber[0]===2)))) {
            //Проверка чтобы одно число нельзя было выбрать
            if (updatedChooseIndex[0]!== updatedChooseIndex[1]){
                playSound("done")
                setScore(score+2)
                // console.log(destroyNum)
                // console.log(gorizNum)
                const newDestroyNum = [...destroyNum, ...updatedChooseIndex]
                //Установка для хода назад
                setBackDestroyNum([...destroyNum])
                setBackGorizNum([...gorizNum])
                setVisBack(true)
                //Изменяем выбранные значения на 0
                const newArray = [...gorizNum]
                updatedChooseIndex.forEach((index,i)=> {
                    newArray[index] = 0
                })
                destroyRow(newDestroyNum, newArray)
                const [newNumbers, newGorizNum] = destroyRow(newDestroyNum, newArray);
                const [newNumbers2, newGorizNum2] = destroyRow(newNumbers, newGorizNum);
                setDestroyNum(newNumbers2);
                setGorizNum(newGorizNum2)
                // console.log(newGorizNum)
                // console.log(newNumbers)

            }

        } else {
            playSound("wrong")
        }
    }
//Лампочка
const check1 = ()=>{
  if (countLamp === 0) {
  setTextVideo("показать подсказку")
  setVisVideo(true)
  setNameFunc("check")
  } else {
    check()
    setCountLamp(countLamp-1)
  }
}

const check = () => {
  setVisVideo(false)
  let i = 0;
  let conditionMet = false; // Флаг, указывающий, было ли выполнено условие
  outerLoop: // метка для внешнего цикла
  for (i = 0; i < gorizNum.length; i++) {
    let j = i + 1;

    for (j = i + 1; j < gorizNum.length; j++) {
      var result = sumElementsBetweenIndexes(gorizNum, i, j);
      var verticalResult = sumVerticalElements(gorizNum, i, j);

      if (gorizNum[i]!==0 && gorizNum[j]!==0 && (gorizNum[i]===gorizNum[j] || gorizNum[i]+gorizNum[j]===10) && (((result-(gorizNum[i]+gorizNum[j])===0)) || ((verticalResult-(gorizNum[i]+gorizNum[j])===0)))) {
        setLampOne(i)
        // setTimeout(()=>{setLampTwo(j)},300)
        setTimeout((index) => {
          setLampTwo(index);
      }, 300, j);
        conditionMet = true;
        playSound("find")
        setTrans('yes')
        // Остановка обоих циклов при выполнении условия
        break outerLoop;
      } 
    }
  }
  // Проверка флага после завершения цикла
  if (!conditionMet) {
    playSound("backWrong")
    setTrans('no')
  }
}

//При просмотре рекламы снова 3 подсказки
const newLamp = () => {
  setCountLamp(3)
}

//До сюда


    //Функция вычисления суммы элементов между двумя элементами
    const sumElementsBetweenIndexes = (arr, startIndex, endIndex) => {
        let sum = 0;
        for (let i = startIndex; i <= endIndex; i++) {
          sum += arr[i];
        }
        // console.log("gorizont")
        return sum;
      }
    //Функция вычисления суммы элементов по вертикали
    const sumVerticalElements = (arr, startIndex, endIndex) => {
        let sum = 0;
        for (let i = startIndex; i <= endIndex; i+=9) {
          if ((endIndex-startIndex)%9 ===0) {
            sum += arr[i];}
        }
        // console.log("vertikal")
        // console.log(sum)
        return sum;
      }

    async function newNumber(number, ind) {
      setLampTwo(null)
      setLampOne(null)
      setTrans(null)
      setIsDesable(true)
      if (playAlloud) {
        playSound("background")
      }
        if (!isRuning) {
            startTimer()
        }
        
        if (chooseNumber.length>0 && chooseNumber.length<2) {
        const updatedChooseNumber = await [...chooseNumber, number];
        setChooseNumber(updatedChooseNumber);
        setChooseIndex([])
        const updatedChooseIndex = await [...chooseIndex, ind]
        comparison(updatedChooseNumber, updatedChooseIndex)
        } 
        else {
            playSound("press")
            setChooseNumber([number])
            setChooseIndex([ind])
        }
    }

    const visAlarm = ()=>{
      setVisAlarmer(true)
    }

    // const [numbersArr, setNumbersArr] = useState([1,2,3,4,5,6,7,8,9,1,1,1,2,1,3,1,4,1,5,1,6,1,7,1,8,1,9])
    
    return (
      <div style={{backgroundColor:"grey", width:"100%"}}>
        <PreloadImages/>
        {visLoading && <Loading/>}
        <div className="App1" style={{backgroundImage: `url(${wall})`, backgroundSize: 'cover', position:"relative"}}>
        <div className="App"  style={{backgroundColor:"rgba(245, 245, 220, 0)"}}>
        {visModal && <Modal textModal={textModal}/>}
        {visAlarmer && <Alarmer closeAlarm={closeAlarm}/>}
        {visWinner && <WinnerScreen allTime={allTime} textWinner={textWinner} textTime={textTime} textTimeShare={textTimeShare}/>}
        {visVideo && <AskVideo textVideo={textVideo} nameFunc={nameFunc} backStep={backStep} check={check} cancelVideo={cancelVideo} newLamp={newLamp}/>}
        {visMenu && <Menu score={score} hideMenu={hideMenu} visAlarm={visAlarm} changeSounds={changeSounds} playSounds={playSounds} playMusic={playMusic} changeMisic={changeMisic} />}
        {visModalShare && <ModalShare noShowAgain={noShowAgain} closeShareCakes={closeShareCakes} countCakes={score}/>}
        <div className='contHead' >
          <div className="headLetter" style={{backgroundImage:`url(${cake})`}}>
            <h1 className='head'>П</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"0.3s"}}>
            <h1 onClick={shuffleArray} disabled={isDesable} className='head' >Е</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"0.6s"}}>
            <h1 className='head'>Ч</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"0.9s"}}>
            <h1 className='head'>Е</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"1.2s"}}>
            <h1 className='head'>Н</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"1.5s"}}>
            <h1 className='head' >Ь</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"1.8s"}}>
            <h1 className='head'>К</h1>
          </div>
          <div className="headLetter" style={{backgroundImage:`url(${cake})`, animationDelay:"2.1s"}}>
            <h1 className='head'>И</h1>
          </div>

        </div>

          <div className="contTimer">

                {/* <img src={timerImg} alt="0" style={{width:"36px", height:"36px", marginRight:"10px"}} /> */}
                <p className="textTimer">{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}</p>
  
          </div>

          {/* <div className="contCountLamp" >
              <p className="countLamp">{countLamp}</p>
          </div> */}
             <div className="countLamp">
               
               {countLamp===0 ? <img src={video} style={{width:"18px", height:"14px", margin:"0px", marginTop:"5px"}} alt=">"/> : <p className="pLamp">{countLamp}</p>}</div>
             <img className="imgButton" onClick={check1} src={lamp} alt="?" style={{animationName: trans}} />


          <div className="contCheck">
            <div className="formCheck" style={{marginBottom:"10px", height:"27px"}}>
               <Switch  size="small" color='warning' value={playMusic} checked={playMusic} onChange={(e)=>{changeMisic(e)}} />
               <img src={music} alt="?" style={{width:"17px", height:"17px", marginRight:"5px"}}/>
            </div>
           <div className="formCheck">
              <Switch  size="small" color='warning' value={playSounds} checked={playSounds} onChange={(e)=>{changeSounds(e)}} />
              <img src={sound} alt="?" style={{width:"16px", height:"16px", marginRight:"5px"}}/>
            </div>
            {/* <img src={cakeBig} alt="H" style={{width:"60px", height:"60px", opacity:"1"}}/> */}
          </div>
          {/* <img src={setting1} alt="|" style={{width:'40px', height:"40px", position:"absolute",top:"100px", left:"43px", opacity:"1"}}/> */}
          <div className="grid-container">
            {gorizNum.map ((number, ind) => (
                <div 
                onClick={()=>(number!==0 && newNumber(number, ind))} 
                style={{
                  
                  backgroundImage: `url(${cake})` ,
                  // color: (lampOne===ind || lampTwo===ind) && "#3FC1C9",
                  // backgroundColor: (lampOne===ind || lampTwo===ind) && "#FC5185",
                    // backgroundColor: destroyNum.includes(ind) ? "#3FC1C9": lampOne===ind || lampTwo===ind ? "#FC5185": '#F5F5F5',
                    // boxShadow: destroyNum.includes(ind) ? "none": '0px 0px 3px rgba(0, 0, 0, 0.6)'
                    opacity: destroyNum.includes(ind) ? "0.3":"1"
                }} 
                    key={ind} 
                    className={(lampOne===ind || lampTwo===ind) ?  "grid-item-anime-2" : chooseIndex.includes(ind) ? "grid-item-anime": "grid-item"}>
                    {number===0 ? 'X': number}
                </div>
            ))}
          </div>
          {/* {visDesk && (!showTextTwo ? <TextOne closeDesc={closeDesc}/>: <TextTwo closeDesc={closeDesc}/>)} */}
          {visDesk && <TextReveal text="Доброе пожаловать в игру печеньки!!! твоя задача купить или не купить и сделать или не сделать" speed={50} />}
          <div className="contButton">
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginLeft:"2px"}}>
             <img src={menuBtn} alt="H" className="cubeButton"  onClick={()=>{setVisMenu(true)}} style={{margin:"0px",marginBottom:"3px",marginTop:"4px", width:"28px", height:"26px"}}/>
             <p style={{margin:"0px", fontSize:"9px", color:"#004225"}}>меню</p>
            </div>
            {/* <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
             <img src={newGame} alt="NG" className="cubeButton"  onClick={()=>{setVisAlarmer(true)}} style={{margin:"0px"}}/>
             <p style={{margin:"0px", fontSize:"9px", color:"#004225"}}>заново</p>
            </div> */}
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", marginLeft:"10px"}}>
              <img src={stepBack} alt="SB" className="cubeButton" onClick={backStep1} />
              <p style={{margin:"0px", fontSize:"9px", color:"#004225"}}>назад</p>
            </div>
            <img src={add} alt="+" className="cubeButton" style={{width:"85px", height:"85px",marginLeft:"10px", marginBottom:"35px", borderRadius:"50%", backgroundImage: `url(${wallBtn})`, backgroundSize: 'cover', boxShadow:"0px 0px 4px rgba(0, 0, 0, 0)"}} onClick={addNumbers}/>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
             <img src={save} alt="S" className="cubeButton"  onClick={saveGame} style={{width:"23px", height:"25px", marginBottom:"5px"}}/>
             <p style={{margin:"0px", fontSize:"9px", color:"#004225"}}>сохранить</p>
           </div>
           <div style={{display:"flex", flexDirection:"column", alignItems:"center", paddingBottom:"6px"}}>
            <img src={downLoad} alt="DL" className="cubeButton" style={{width:"35px"}} onClick={loadGame}/>
            <p style={{margin:"0px", fontSize:"9px", color:"#004225"}}>загрузить</p>
            </div>
        </div>

          <audio id="press" src={soundPress}></audio>
          <audio id="done" src={soundDone}></audio>
          <audio id="wrong" src={soundWrong}></audio>
          <audio id="add" src={soundAdd}></audio>
          <audio id="back" src={soundBack}></audio>
          <audio id="backWrong" src={soundBackWrong}></audio>
          <audio id="saveGame" src={soundSaveGame}></audio>
          <audio id="loadGame" src={soundLoadGame}></audio>
          <audio id="reload" src={soundReload}></audio>
          <audio id="win" src={soundWin}></audio>
          <audio id="find" src={soundFind}></audio>
          <audio id="result" src={newResult}></audio>
          {playMusic && playMusic1 && <audio id="background" src={soundBackground} loop ref={audioRef} ></audio>}
        </div>
        </div>
        </div>
      );
  }

export {Cube};

// style={ind===chooseIndex ? {opacity:'0.5'}:{}}